.app_container {

}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.Button {
  color: green;
}